<template>
  <div class="shop">
    <!-- 标题 -->
    <!-- <div>
      <van-sticky>
        <van-nav-bar title="便民商店" />
      </van-sticky>
    </div> -->
    <!-- tab栏 -->
    <div class="information">
      <!-- 全部 -->
      <van-list v-model="loading"
        :finished="finished"
        offset="50"
        @load="onLoad">
        <div class="center"
          v-for="item in list"
          :key="item.index">
          <!-- <router-link :to="'/article/detail/' + item.RIId"> -->
          <div style="display: flex;justify-content: space-between;">
            <div class="shopTitle van-ellipsis"
              style="width: 60%;">{{ item.Title }}</div>
            <!-- <div class="shopTitle"
              style="color:tomato;">{{ item.ShopCateDesc }}</div> -->
          </div>
          <div class="Content"
            style="padding: 8px 10px">
            <div style="display: inline-block"
              class="ContentA">
              <van-image style="width: 100%; height: 85px"
                :src="item.ShopImgUrl">
                <template style="width: 100%; height: 85px"
                  v-slot:error>
                  <img src="http://lccgzapi.zhsq.cloud//upload/images/2021/11/b6b3ab86-df3.png"
                    alt="" />
                </template>
              </van-image>
            </div>
            <div style="display: inline-block;"
              class="ContentB">
              <span style="color: #333"
                class="van-multi-ellipsis--l3">
                <van-icon name="location" />{{ item.Address }}</span>
              <br />
              <span style="font-size: 12px; color: #333">{{ item.IName }}</span>
              <br />
              <span class="centertime"
                style="color: #333">
                <van-icon name="manager" />{{ item.Charger }}
                <van-icon name="phone" /><a :href="'tel:' + item.Mobile"
                  style="color:black;">{{ item.Mobile }}</a>
              </span>
            </div>
            <div style="display: inline-block;width: 100%;text-align: right;padding-right:5px;margin-left: 0px;"
              class="ContentB">
              <span class="centertime"
                style="color: #333">

                <van-button round
                  style="width:80px;margin:5px;"
                  @click="shopBangDing(item)"
                  size="mini">微信绑定</van-button>
                <van-button type="warning"
                  round
                  style="width:80px;margin:5px;"
                  @click="entYuanGong(item)"
                  v-if="item.ShopCate == 1"
                  size="mini">员工信息</van-button>
                <van-button type="warning"
                  round
                  v-if="item.ShopCate == 2"
                  style="width:80px;margin:5px;"
                  @click="shopOrder(item)"
                  size="mini">商铺订单</van-button>
                <van-button type="primary"
                  round
                  v-if="item.ShopCate == 2"
                  style="width:80px;margin:5px;"
                  @click="shopGoodsList(item)"
                  size="mini">商品列表</van-button>
                <van-button type="primary"
                  round
                  v-if="item.ShopCate == 1"
                  style="width:80px;margin:5px;"
                  @click="shopPromotionList(item.ShopId)"
                  size="mini">促销活动</van-button>
                <van-button type="info"
                  round
                  style="width:60px;"
                  @click="shopUpdata(item)"
                  size="mini">编辑</van-button>
              </span>
            </div>
          </div>
          <!-- </router-link> -->
        </div>
      </van-list>
      <!-- 没有更多数据了 -->
      <div v-if="hidden"
        class="hidden">───── 没有更多数据了 ─────</div>
      <div>
        <div style="width: 100%; bottom: 0; position: fixed; padding: 10px">
          <!-- <van-button round
            type="info"
            size="normal"
            style="text-center: right; font-size: 16px; width: 45%"
            @click="addShop()"
            color="#617bfa">便民商铺</van-button>
          <van-button round
            type="info"
            size="normal"
            style="text-center: right; font-size: 16px; width: 45%"
            @click="addGoodType()"
            color="#617bfa">积分商城</van-button> -->
          <!-- <van-button round
            type="info"
            size="normal"
            style="text-center: right; font-size: 16px; width: 45%"
            @click="addGoodType()"
            color="#617bfa">商品分类</van-button> -->
          <!-- <van-button round
            type="info"
            size="normal"
            style="text-center: right; font-size: 16px; width: 45%"
            @click="addShop()"
            color="#617bfa">便民商铺</van-button> -->
          <van-button round
            type="primary"
            size="normal"
            style="
              text-center: right;
              font-size: 16px;
              width: 95%;
            "
            @click="addJfShop()">新增</van-button>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { WxGetMyShopList } from "@/api/RealInfo";
import { setOpenId, getOpenId } from "@/utils/auth";
export default {
  data () {
    return {
      riKind: 0, //tab栏
      list: {}, //资讯列表
      listfrom: {
        page: 1,
        limit: 10,
      }, //查询传参
      search: "", //搜索内容
      hidden: true, //没有更多数据了
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
    };
  },
  methods: {
    // 员工信息
    entYuanGong (row) {
      console.log(row);
      this.$router.push({
        // name: "ygList",
        path: "/shop/myShop/ygList/" + row.ShopId + "/" + 2,
        // query: { seId: row.EId, ekind: 1 },
      });

    },
    // 商品列表
    shopGoodsList (row) {
      console.log(row);
      this.$router.push({
        path: "/shop/myShop/goodsList/" + row.ShopId + '/' + row.ShopCate,
      });
    },
    // 促销活动
    shopPromotionList (row) {
      console.log(row);
      this.$router.push({
        path: "/shop/myShop/Promotion/" + row,
      });
    },
    //新增商品分类
    addGoodType () {
      this.$router.push({
        path: "/shop/myShop/goodsTypeList/",
      });
    },
    //新增商铺
    addShop () {
      this.$router.push({
        name: "addShop",
        query: { bfaId: this.itemmark, add: 1, shopCate: 1 },
      });
    },
    // 积分商城
    addJfShop () {
      this.$router.push({
        name: "addShop",
        query: { bfaId: this.itemmark, add: 1, shopCate: 2 },
      });
    },
    //重新绑定
    shopBangDing (row) {
      this.$router.push({
        name: "addShop",
        query: { shopId: row.ShopId, add: 3, shopCate: row.ShopCate },
      });
    },
    //编辑
    shopUpdata (row) {
      this.$router.push({
        name: "addShop",
        query: { shopId: row.ShopId, add: 2, shopCate: row.ShopCate },
      });
    },
    // 商铺订单
    shopOrder (row) {
      this.$router.push({
        path: "/shop/myShop/myShopOrder/" + row.ShopId,
      });
    },
    // 获取分页列表
    getList () {
      var opdnid = getOpenId();
      WxGetMyShopList({ openID: opdnid })
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              // this.hidden = true;
              this.finished = true;
              this.list = {};
            } else {
              this.list = res.data.data;
              this.total = res.data.count;
              this.finished = true;
              // this.hidden = false;
              for (var i = 0; i < this.list.length; i++) {
                var item = this.list[i].IssTime;
                item = this.dataFormat(item);
                this.list[i].IssTime = item;
                if (this.list[i].ShopImgUrl != "rejected") {
                  this.list[i].ShopImgUrl =
                    "https://cuiyunapi.dexian.ren//upload/images/2021/7/873b94ff-81d.png";
                }
              }
            }
          }
        })
        .catch(() => { });
    },

    // 下拉刷新
    onLoad () {
      this.listfrom.page = this.listfrom.page + 1;
      this.getList();
    },
    // tan点击切换事件
    onClick (e) {
      this.riKind = e;
      this.listfrom.page = 1;
      this.getList();
    },
    // 搜索
    onSearch () {
      this.listfrom.page = 1;
      this.getList();
    },
    // 时间格式化
    dataFormat (value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
  },
  mounted () {
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    // setOpenId("oebDw5bQTDtkwSQJAsKA-uKSWitM");
    this.getList();
  },
};
</script>
<style>
.shopTitle {
  color: #333;
  padding: 10px;
}
.shop .information .center {
  border-bottom: none;
  padding: 0px;
  border-radius: 5px;
}
.shop .center {
  width: 92%;
  margin: 10px auto;
  /* background: rgb(246, 243, 243); */
  box-shadow: 0px 5px 17px 1px rgb(0 0 0 / 7%);
}
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
</style>